<template>
  <SetupLayout
    :app="false"
    :title="title"
    :toolbar-title="toolbarTitle"
    :hide-app-bar="hideStoreSettingsAppBar"
    @back="onBack"
  >
    <template #app-bar-right>
      <template v-if="isTeamMode">
        <LockButton />
        <v-fade-transition leave-absolute>
          <EmployeeAvatar
            v-if="$store.state.teamMode.employee"
            :employee="$store.state.teamMode.employee"
            status-size="14"
            size="32"
            tooltip
          />
        </v-fade-transition>
      </template>
      <StoreSelect v-else />
      <RightMenu />
    </template>
    <template #description>
      <template v-if="Array.isArray(description)">
        <p v-for="(desc, idx) of description" :key="idx">
          {{ desc }}
        </p>
        <v-divider />
      </template>
      <template v-else>
        <p>{{ description }}</p>
      </template>
    </template>
    <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
  </SetupLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { SetupLayout } from "@/core-ui";
import { findMetaField } from "@/utils/router";

export default {
  name: "StoreSettingsView",
  components: {
    SetupLayout,
    LockButton: () => import("@/components/lock/LockButton"),
    EmployeeAvatar: () => import("@/components/employees/EmployeeAvatar"),
    StoreSelect: () => import("@/components/StoreSelect"),
    RightMenu: () => import("@/components/RightMenu"),
  },
  props: {
    hideStoreSettingsAppBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("auth", ["isTeamMode"]),
    title() {
      return findMetaField(this, "title");
    },
    toolbarTitle() {
      return findMetaField(this, "toolbarTitle");
    },
    description() {
      return findMetaField(this, "description");
    },
  },
  methods: {
    onBack() {
      this.$router.back();
    },
  },
};
</script>
